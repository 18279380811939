<template>
    <span class="">
        <v-tooltip top v-if="toolTip">
            <template v-slot:activator="{ on }">
                <v-icon @click="handleClick()" v-on="on" :size="size" :color="color"> {{ glyph }} </v-icon>
            </template>
            <!-- <span style="background-color: white; border: green solid 1px; color:green">{{ toolTip }}</span> -->
            <template v-slot:default>
                <div class="custom-tooltip" :style="{ 'borderColor': color, 'color': color }">
                    {{ toolTip }}
                </div>
            </template>
        </v-tooltip>

        <v-icon v-if="!toolTip" @click="handleClick()" :size="size" :color="color"> {{ glyph }} </v-icon>
    </span>

</template>
<script>
export default {
    props: {
        size: { type: Number, default: 20 },
        color: { type: String, default: "green" },
        glyph: { type: String },
        toolTip: { type: String },
    },
    methods: {
        handleClick() {
            this.$emit("click");
        },
    },
};
</script>
<style scoped>
.custom-tooltip {
    background-color: #fafafa;
    padding: 2px 8px 2px 8px;
    border-radius: 5px;
    border: solid 1px;
    font-size: 12px;
}

/* Remove gray background added by Vuetify */
.v-tooltip__content {
    background: none;
}
</style>